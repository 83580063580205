import React, { useEffect, useRef } from "react";
import Footer from "../../Component/Footer/Footer";
import NavBar from "../../Component/NavBar/NavBar";
import "./styles/cll.min.css";
import "./styles/style.css";
import "./styles/tedia.css";

const Special = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <section id="banner" class="Banner_others section_gaps">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>কনফিডেন্সের বৈশিষ্ট</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps" ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content">
                <h3>কনফিডেন্সের বৈশিষ্ট</h3>
                <p className="mt-3">
                  প্রায় সব প্রতিষ্ঠানেরই কিছু বৈশিষ্ট কাগজে কলমে উল্লেখ থাকে।
                  এখানে আমাদের যে বৈশিষ্টগুলো উল্লেখ করা হয়েছে তার প্রত্যেকটিই
                  কার্যত মেনে চলা হয় ।{" "}
                </p>
                <ul>
                  <li>
                    <b> দেশ-সেরা ও অভিজ্ঞ শিক্ষকমন্ডলী । </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      কোর্সের শুরুতে শিক্ষকগণ কর্তৃক সকল বিষয়ের পূর্ণাঙ্গ কোর্স
                      প্ল্যান প্রার্থীদের সরবরাহ করা ।{" "}
                    </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      ইংরেজি ও গণিতে অতিরিক্ত ক্লাস প্রদান এবং প্রত্যেকটি
                      বিষয়ভিত্তিক টেস্ট, সাপ্তাহিক টেস্ট, মাসিক টেস্ট, মূল্যায়ন
                      টেস্ট, রিভিউ টেস্ট, সাম্প্রতিক বিষয়াবলির উপর টেস্ট এর
                      সাথেও অংক ইংরেজি যুক্ত করা হয়েছে।{" "}
                    </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      BCS প্রিলি, কোর্সের ১৩৪ টি ২ ঘন্টার ক্লাস, ১৩৪ টি
                      ক্লাস-টেস্ট, ১৩৪টি লেকচার শীট প্রদান করা হয় ।{" "}
                    </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      বিসিএস মানের প্রশস্ত ও শীততাপ নিয়ন্ত্রিত মনোরম লাইব্রেরি ।{" "}
                    </b>
                  </li>

                  <li>
                    <b> ইন্টারনেটে পরীক্ষার ফলাফল প্রকাশ । </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      মেধা তালিকা প্রণয়ন ও পুরস্কার প্রদান । টার্গেট পরীক্ষার
                      মাধ্যমে প্রতিটি বিষয় প্রস্তুতি সমাপন করা হয়।
                    </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      পরীক্ষা সমূহ: Class Test-134 Weekly Test-24 Review Test-10
                      Evaluation Test-6 Memory Test-12 Model Test-10 Final Model
                      Test-10 Group Study{" "}
                    </b>
                  </li>

                  <li>
                    <b>
                      {" "}
                      Class Test: BPSC প্রণীত সৃজনশীল সিলেবাসকে ১৩৪টি টপিকে ভাগ
                      করে প্রতিটি ২০ নম্বরের পরীক্ষার মাধ্যমে সমাপ্ত করা হয় ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Weekly Test: প্রতি সপ্তাহের ক্লাস ও গুরুত্বপূর্ণ টপিকের
                      উপরে ৫০ নম্বরের সাপ্তাহিক টেস্ট নেয়া হয়।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Evaluation Test: পূর্ববর্তী প্রশ্নের আলোকে সিলেবাস ভাগ করে
                      প্রতিটি ২০০ নম্বরের ৬টি মূল্যায়ন পরীক্ষা নেয়া হয়।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Review Test: BPSC এর সৃজনশীল সিলেবাস ও পূর্ববর্তী ক্যাডার,
                      নন ক্যাডার এর প্রশ্নের আলোকে সিলেবাস প্রণয়নের মাধ্যমে
                      প্রতিটি ২০০ নম্বরের করে ১০ টি রিভিউ টেস্ট নেয়া হয় ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Memory/ Open Book Test: প্রতি সপ্তাহে ২টি লেকচার শিট
                      অর্থাৎ মাসে ৮টি লেকচার শিট ও গুরুত্বপূর্ণ বইয়ের উপরে মাস
                      শেষে ২ ঘন্টা ব্যাপী এই পরীক্ষা হয়ে থাকে ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Monthly Test: সমগ্র মাসের ক্লাস, উক্ত মাসের সাম্প্রতিক
                      শিট, পাটিগণিত/বীজগণিত/জ্যামিতি যে কোন একটি অংশ, ইংরেজির
                      একটি অংশ ও বিবিধ (পত্র-পত্রিকা ও বিভিন্ন জার্নাল থেকে
                      উল্লেখযোগ্য অংশ) নিয়ে প্রতিমাসে ২০০ নম্বরের Monthly Test
                      অনুষ্ঠিত হয় ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Assignment Test: BCS কনফিডেন্স এ ১৩ টি বিষয় ভিত্তিক
                      Assignment Test অনুষ্ঠিত হবে। প্রথমে সংশ্লিষ্ট বইটি একবার
                      রিডিং পড়ে দ্বিতীয় বার রিডিং পড়ার সময় গুরুত্বপূর্ণ টপিক
                      গুলো Underline করতে হবে । অবশেষে Underline - কৃত অংশ থেকে
                      ২০০/৩০০ প্রশ্ন ও তার উত্তর এবং ৫০/১০০ টিকা লিখে জমা দিতে
                      হবে । পরীক্ষায় প্রশ্নপত্রে উত্তর লিখতে হবে ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      বিষয়ভিত্তিক টেস্ট (Subjective Test) : বিসিএস পরীক্ষার
                      সিলেবাস অনুযায়ী ১২টি বিষয় ভিত্তিক পরীক্ষা ইংরেজি, বাংলা,
                      গণিতসহ, বিষয়গুলোতে ২০০ নম্বর করে ১২টিতে ১২x২০০=২৪০০
                      নম্বরের বিষয় ভিত্তিক পরীক্ষা অনুষ্ঠিত হবে। পরীক্ষায়
                      প্রতিটি ঠিক উত্তরের জন্য ১ নম্বর এবং ভুল উত্তরের জন্য ০.৫
                      নম্বর কাটা যাবে। O.M.R মেশিনে খাতা কাঁটা হবে। রোল নম্বর ও
                      রেজিস্ট্রেশন নম্বর না লিখলে খাতা বাতিল বলে গণ্য হবে ।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Recent Topic Test (RTT): পরীক্ষার আগের ৬ মাসের বাংলাদেশ ও
                      আন্তর্জাতিক বিষয়াবলির সকল গুরুত্বপূর্ণ ঘটনার উপরে ২০০
                      নম্বরের করে ৩টি সাম্প্রতিক টেস্ট নেওয়া হবে।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Model Test: ১০টি মডেল টেস্ট ও ৫টি চুড়ান্ত মডেল টেস্ট নেওয়া
                      হবে। পরীক্ষা শুরু প্রতি বৎসর চুড়ান্ত পরীক্ষার ২ মাস
                      পূর্বে। প্রতিটি প্রশ্নের ৪টি অপশনে ১টি উত্তর (ঠিক) থাকবে।
                      প্রতিটি ঠিক উত্তরের জন্য ১ নম্বর এবং ভুল উত্তরের জন্য ০.৫
                      নম্বর কাঁটা যাবে। O.M.R মেশিন খাতা কাটা হবে। রোল নম্বর ও
                      রেজিস্ট্রেশন না লিখলে খাতা বাতিল বলে গণ্য হবে। এ খাতা
                      মূল্যায়ন হবে সারা বাংলাদেশে কনফিডেন্সের সকল
                      ছাত্র-ছাত্রীদের সাথে।
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      Final Model Test (FMT): BPSC এর মত সিলেবাস ফলো করে ৩টি
                      (প্রতিটি ২০০ নম্বরের করে) পরীক্ষা নেওয়া হবে।
                    </b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Special;
